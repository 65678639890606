import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import map from "./landing";
import signIn from "./signIn";

// S1 Sign-In Page
const Home = () => {
  return (
    signIn()
  );
};

// S2 Landing Page
const Landing = () => {
  return (
    map()
  );
};

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="*" element={<Navigate replace to="/landing" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}