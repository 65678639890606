import React from "react";
import data from './data/us-10m.json';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Modal, Paper, Typography } from '@material-ui/core';
import { Bookmark, Close, ExpandMore, Flag, Person, PersonOutlined, PlaceOutlined, HelpOutline } from "@material-ui/icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { Colors } from './colors.js';
import { VegaLite } from 'react-vega';

const background = '/img/background_white.svg';
const MunichRE = '/img/MR_Logo_blue_RGB.svg';
const HSB = '/img/HSB_vrt_Blue.svg';
const device = './img/device.svg';

const dividerLineStyle = {
  height: 42,
  border: "1px solid",
  color: Colors.darkblue,
  backgroundColor: Colors.darkblue,
  borderColor: Colors.darkblue,
  marginTop: -4,
  marginLeft: 100
}
const policyBoxStyle = {
  backgroundColor: Colors.blue, color: 'white', fontSize: '30', marginTop: '7px', marginLeft: '7px', marginBottom: '7px'
}
const contractBoxStyle = {
  backgroundColor: 'white', color: Colors.blue, fontSize: '30', marginTop: '7px', marginLeft: '7px', marginBottom: '7px'
}
const policyInCardStyle = {
  backgroundColor: Colors.blue, color: 'white', fontSize: '30', marginRight: '7px', marginTop: '2px'
}
const contractInCardStyle = {
  backgroundColor: 'white', color: Colors.blue, fontSize: '30', marginRight: '7px', marginTop: '2px'
}
const claimInCardStyle = {
  backgroundColor: 'white', color: Colors.claim, fontSize: '30', marginRight: '7px'
}
const boxStyle = { backgroundColor: 'white', color: Colors.grey, border: "1px solid", borderRadius: '10px' }
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
const buttonStyle = {
  backgroundColor: Colors.blue, mt: 3, mb: 2, color: "white"
}
const NewStyle = {
  color: Colors.new, fontSize: '50'
}
const ActiveStyle = {
  color: Colors.active, fontSize: '50'
}
const InactiveStyle = {
  color: Colors.inactive, fontSize: '50'
}
const ClaimStyle = {
  color: Colors.claim, fontSize: '50'
}

var locations = [
];

const getAccessToken = () => {
  return sessionStorage.getItem('access-token');
}

var mapData = {
  "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
  "width": 900,
  "height": 590,
  "background": 'transparent',
  "config": {
    "style": {
      "cell": {
        "stroke": "transparent"
      }
    }
  },
  "layer": [
    {
      "data": {
        "values": data,
        "format": {
          "type": "topojson",
          "feature": "states"
        }
      },
      "projection": {
        "type": "albersUsa"
      },
      "mark": {
        "type": "geoshape",
        "fill": "white",
        "stroke": "lightgray"
      }
    },
    {
      "data": { name: 'table' },
      "projection": {
        "type": "albersUsa"
      },
      "mark": {
        "type": "circle"
      },
      "transform": [{
        "calculate": "datum.url", "as": "url"
      }],
      "encoding": {
        "url": { "field": "image" },
        "longitude": {
          "field": "longitude",
          "type": "quantitative",
          "axis": null
        },
        "latitude": {
          "field": "latitude",
          "type": "quantitative",
          "axis": null
        },
        "size": { "value": 800 },
        "href": { "field": "url", "type": "nominal" },
        "color": {
          "scale": {
            "domain": ["Active", "Inactive", "New Device", "Claim"],
            "range": [Colors.active, Colors.inactive, Colors.new, Colors.claim]
          },
          "field": "status",
          "type": "nominal",
          "legend": { "orient": "bottom-right", "direction": "horizontal", "labelFontSize": 20, "title": null }
        }
      }
    },
    {
      "data": { name: 'table' },
      "projection": {
        "type": "albersUsa"
      },
      "transform": [{
        "calculate": "datum.url", "as": "url"
      }],
      "mark": { "type": "image", "width": 20, "height": 20 },
      "encoding": {
        "url": { "field": "image" },
        "longitude": {
          "field": "longitude",
          "type": "quantitative"
        },
        "latitude": {
          "field": "latitude",
          "type": "quantitative"
        },
        "size": { "value": 250 },
        "href": { "field": "url", "type": "nominal" },
        "color": {
          "scale": {
            "domain": ["Active", "Inactive", "New Device", "Claim"],
            "range": [Colors.active, Colors.inactive, Colors.new, Colors.claim]
          },
          "field": "status",
          "type": "nominal",
          "legend": { "orient": "bottom-right", "direction": "horizontal", "labelFontSize": 20, "title": null }
        }
      }
    }
  ]
};

export default function Map() {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", getAccessToken());

  let location = useLocation();
  const [modalData, setModalData] = React.useState({});
  const [approved, setApproved] = React.useState(false);
  const [rejected, setRejected] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [locationData, setLocationData] = React.useState([])
  const [devices, setDevices] = React.useState([])
  const [contracts, setContracts] = React.useState([])
  const getLocationData = () => {
    if (getAccessToken()) {
      const devicesFetch = fetch("/devices", {
        method: "GET",
        headers: myHeaders
      }).then((response) => response.json())
      const contractsFetch = fetch("/contracts", {
        method: "GET",
        headers: myHeaders
      }).then((response) => response.json())
      const claimsFetch = fetch("/claims", {
        method: "GET",
        headers: myHeaders
      }).then((response) => response.json())

      Promise.all([devicesFetch, contractsFetch, claimsFetch]).then(async (responses) => {
        var devices = responses[0]
        setDevices(devices)
        var contracts = responses[1]
        setContracts(contracts)
        var claims = responses[2]
        var claimContracts = []
        var claimDevices = []
        var activeDevices = []
        claims.forEach((claim) => {
          if (claim.state == "IN_PROGRESS") {
            claimContracts.push(claim.contractId)
          }
        })
        contracts.forEach((contract) => {
          if (claimContracts.indexOf(contract.contractId) < 0) {
            activeDevices.push(contract.deviceId)
          } else {
            claimDevices.push(contract.deviceId)
          }
        })
        var newLocations = []
        for (var j = 0; j < devices.length; j++) {
          var status = (activeDevices.indexOf(devices[j].deviceId) < 0) ? "New Device" : "Active";
          status = (claimDevices.indexOf(devices[j].deviceId) < 0) ? status : "Claim";
          newLocations.push({
            "deviceId": devices[j].deviceId,
            "longitude": Number(devices[j].attributes.Longitude.value),
            "latitude": Number(devices[j].attributes.Latitude.value),
            "status": status,
            "url": "#" + devices[j].deviceId,
            "image": ""
          });
        }
        setLocationData({ "table": newLocations })
      });
    }
  }
  if (locationData.length === 0) {
    if (window.locationDataInterval) {
      clearInterval(window.locationDataInterval)
    }
    getLocationData()
    window.locationDataInterval = setInterval(getLocationData, 5000)
  }

  // show modal popup after time delay
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location.hash = "";
  };
  const handleApproval = (claimId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", getAccessToken());
    fetch("/claims/" + claimId + "/acceptance", {  // Enter your IP address here
      method: 'POST',
      body: '{"action":"ACCEPT"}', // body data type must match "Content-Type" header
      headers: myHeaders
    })
    console.log(claimId)
    setApproved(true);
  }
  const handleReject = (claimId) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", getAccessToken());
    fetch("/claims/" + claimId + "/acceptance", {  // Enter your IP address here
      method: 'POST',
      body: '{"action":"REJECT"}', // body data type must match "Content-Type" header
      headers: myHeaders
    })
    setRejected(true);
  }

  var route;
  React.useEffect(() => {
    route = location.hash.split('#')[1];
    if (route) {
      getModalData(route);
      handleOpen();
    }
  }, [location]);

  async function getModalData(deviceId) {
    var deviceModel = "";
    var deviceLocation = "";

    var insuredEntity = "";
    var policyId = "";
    var policyType = "";
    var policyStartDate = "";
    var policyEndDate = "";
    var policySpecificInformation = "";
    var policyNumber = "";
    var beneficiary = "";
    var contractStartDate = "";
    var contractEndDate = "";
    var contractSpecificInformation = "";
    var contractId = "";
    var contractNumber = "";

    var claimId = "";
    var claimDate = "";
    var incidentDate = "";
    var contractAndPolicySpecificInformation = "";
    var claimCaseNumber = "";
    let status = await checkDeviceId(deviceId);
    //Unknown device
    if (status) {
      status = "Unknown Device"
    } else {

      var deviceData = {}
      for(const device of devices){
        if(device.deviceId == deviceId) {
            deviceData = device;
            break;
        }
      }
      var contractData = {}
      for(const contract of contracts){
        if(contract.deviceId == deviceId) {
            contractData = contract;
            contractId = contract.contractId;
            break;
        }
      }
      status = (contractId === "") ? "New Device" : "Active";
      deviceModel = deviceData.attributes.Model.value;
      deviceLocation = [deviceData.attributes.Location_Street.value, deviceData.attributes.Location_City.value, deviceData.attributes.Location_Country.value]
      if (contractId === "") {
        status = "New Device";
      } else {
        // Get contract information
        beneficiary = contractData.beneficiary;
        deviceId = contractData.deviceId;
        policyId = contractData.policyId;
        //contractSpecificInformation = contractData.contractSpecificInformation;
        contractStartDate = formatDateTime(contractData.contractStartDate);
        contractEndDate = formatDateTime(contractData.contractEndDate);
        contractNumber = contractData.contractNumber;

        // Get policy information
        await fetch("/policies/" + policyId, {
          method: "GET",
          headers: myHeaders
        })
          .then((response) => response.json())
          .then((policyData) => {
            insuredEntity = policyData.insuredEntity;
            policyId = policyData.policyId;
            policyType = policyData.policyType;
            policyStartDate = formatDateTime(policyData.policyStartDate);
            policyEndDate = formatDateTime(policyData.policyEndDate);
            policyNumber = policyData.policyNumber;
            //policySpecificInformation = policyData.policySpecificInformation;
          });
        // Get claim information
        claimId = await getClaimIdwithContractId(contractId);
        if (claimId !== "") {
          status = "Claim Approval Pending";
          await fetch("/claims/" + claimId, {
            method: "GET",
            headers: myHeaders
          })
            .then((response) => response.json())
            .then((claimData) => {
              console.log(claimData.claimDate)
              claimDate = formatDateTime(claimData.claimDate);
              incidentDate = formatDateTime(claimData.incidentDate);
              contractAndPolicySpecificInformation = claimData.contractAndPolicySpecificInformation;
              claimCaseNumber = claimData.claimCaseNumber;
            });
        }
      }
    }

    setModalData({
      "status": status, "deviceModel": deviceModel,
      "insuredEntity": insuredEntity, "policyId": policyId, "policyType": policyType, "policyStartDate": policyStartDate, "policyEndDate": policyEndDate, "policySpecificInformation": policySpecificInformation,
      "beneficiary": beneficiary, "contractId": contractId, "contractStartDate": contractStartDate, "contractEndDate": contractEndDate, "contractSpecificInformation": contractSpecificInformation,
      "policyNumber":policyNumber,
      "contractNumber":contractNumber,
      "location": deviceLocation,
      "claimId": claimId, "claimDate": claimDate, "incidentDate": incidentDate, "contractAndPolicySpecificInformation": contractAndPolicySpecificInformation, "claimCaseNumber":claimCaseNumber,
      "img": "/img/S33.png"
    });
  }
  return (
    <Paper style={{
      width: "100%", height: "100%", overflow: "hidden"
    }}>
      <Header />
      <Box display="grid" style={{
        backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat",
        width: "100%", height: "100vh", overflow: "auto"
      }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
          <Box gridColumn="span 12" display="flex" justifyContent="flex-start" alignItems="flex-start"
            style={{ marginTop: 45, marginLeft: "15%", height: "20px" }}>
            <Box gridColumn="span 4" display="flex" justifyContent="flex-start" alignItems="flex-start" style={boxStyle}>
              <PersonOutlined style={policyBoxStyle} />
              <Typography component="h1" variant="body1" style={{ backgroundColor: 'white', color: Colors.grey, marginTop: '12px', marginLeft: '8px', marginRight: '8px' }}>
                Policy Holder: Schneider Electric
              </Typography>
            </Box>
            <Box gridColumn="span 4" display="flex" justifyContent="flex-start" alignItems="flex-start" style={boxStyle} marginLeft="40px">
              <Person style={contractBoxStyle} />
              <Typography component="h1" variant="body1" style={{ backgroundColor: 'white', color: Colors.grey, marginTop: '12px', marginLeft: '6px', marginRight: '8px' }}>
                Contract Holder: Home Depot
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="grid" style={{ marginTop: "-1%" }}>
          <Grid item xs container justifyContent="center">
            <VegaLite spec={mapData}
              data={locationData}
              actions={false}
            />
          </Grid>
        </Box>

        <Modal open={open} onClose={handleClose}      >
          <Box display="grid" style={modalStyle} sx={{ width: { xs: '90%', sm: '50%', md: '45%' } }} >
            <DeviceDetails handleClose={handleClose} handleApproval={handleApproval} handleReject={handleReject}
              modalData={modalData} approved={approved} rejected={rejected} />
          </Box>
        </Modal>
      </Box>
    </Paper >
  );
}

function formatDateTime(datetime) {
  datetime = new Date(Date.parse(datetime));
  var year = datetime.getFullYear();
  var month = datetime.getMonth() + 1;
  var day = datetime.getDate();
  var hours = datetime.getHours();
  var minutes = datetime.getMinutes();
  return `${year}-${month}-${day}, ${hours}:${minutes}`.toString();
}

async function checkDeviceId(deviceId) {
  var availableDevices = [];

  const myHeaders = new Headers();
  myHeaders.append("Authorization", getAccessToken());
  await fetch("/devices", {
    method: "GET",
    headers: myHeaders
  })
    .then((response) => response.json())
    .then((devices) => {
      devices.forEach((device) => {
        availableDevices.push(device.deviceId)
      })
    });
  return availableDevices.indexOf(deviceId) < 0;
}

async function getContractIdwithDeviceId(deviceId) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", getAccessToken());

  let contractId = "";
  await fetch("/contracts", {
    method: "GET",
    headers: myHeaders
  })
    .then((response) => response.json())
    .then((contracts) => {
      for (var j = 0; j < contracts.length; j++) {
        if (deviceId === contracts[j].deviceId) {
          contractId = contracts[j].contractId;
        }
      }
    });
  return contractId;
}

async function getClaimIdwithContractId(contractId) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", getAccessToken());

  let claimId = "";
  await fetch("/claims", {
    method: "GET",
    headers: myHeaders
  })
    .then((response) => response.json())
    .then((claims) => {
      for (var j = 0; j < claims.length; j++) {
        if (contractId === claims[j].contractId && claims[j].state == "IN_PROGRESS") {
          claimId = claims[j].claimId;
        }
      }
    });
  return claimId;
}

function DeviceDetails({ handleClose, handleApproval, handleReject, modalData, approved, rejected }) {
  var status = "Unknown";
  if (modalData.status) {
    status = modalData.status.split(' ')[0];
  }
  if (!modalData.location) {
    modalData.location = []
  }
  var claimModal = (status === "Claim");
  return (
    <>
      {claimModal && approved && <Approval handleClose={handleClose} />}
      {claimModal && rejected && <Rejection handleClose={handleClose} />}
      {(status === "Unknown") &&
        <Card>
          <CardHeader
            style={{ backgroundColor: Colors.darkblue, color: "white" }}
            title="Unknown Device"
            titleTypographyProps={{ variant: 'h5' }}
            action={
              <IconButton style={{ color: "white", marginTop: 10 }} data-dismiss="alert" data-target="#closeablecard" onClick={handleClose}>
                <Close />
              </IconButton>
            }
            avatar={
              <HelpOutline style={eval(`${"Inactive" + "Style"}`)} />
            }
          />
        </Card>
      }
      {(status !== "Unknown") && (!claimModal || (!approved && !rejected)) &&
        <Card>
          <CardHeader
            style={{ backgroundColor: Colors.darkblue, color: "white" }}
            title={"Status: " + modalData.status}
            titleTypographyProps={{ variant: 'h5' }}
            action={
              <IconButton style={{ color: "white", marginTop: 10 }} data-dismiss="alert" data-target="#closeablecard" onClick={handleClose}>
                <Close />
              </IconButton>
            }
            avatar={
              <Bookmark style={eval(`${status + "Style"}`)} />
            }
          />
          <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
            <CardContent>
              <img alt="device" src={device} />
              <Typography component="h1" variant="h5">
                <strong>{modalData.deviceModel}</strong>
              </Typography>
              {
                (status !== "New") &&
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="policy-content"
                    id="policy-header"
                  >
                    <PersonOutlined style={policyInCardStyle} />
                    <Typography variant="h6" style={{ color: Colors.darkblue }}>Policy Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} alignItems="flex-start" direction="row">
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy Holder:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.insuredEntity}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy No.:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.policyNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy Type:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.policyType}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy Start Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.policyStartDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy End Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.policyEndDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Policy Details:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Contractual Liability Coverage Type
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          Quarterly Reporting Form
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Premium Payment Plan
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          Minimum and Deposit Premium (Reporting Form Only). Minimum Premium: $10,000 Minimum and Deposit Premium is the non-refundable initial payment due
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Contractual Liability Coverage
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $10,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Terrorism
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $0
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Total Premium Due
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $10,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          HSB Limit of Liability
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          Event Limit: 4 claims per Protected Equipment during the guarantee period<br />Aggregate Per equipment Limit: $100,000<br />Per Event financial limit: $25,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Annual Policy Limit
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $2,000,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Deductible retained by the Insured
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $0
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              }
              {
                (status !== "New") &&
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="contract-content"
                    id="contract-header"
                  >
                    <Person style={contractInCardStyle} />
                    <Typography variant="h6" style={{ color: Colors.darkblue }}>Contract Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} alignItems="flex-start" direction="row">
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Contract Holder:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.beneficiary}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Contract No.:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.contractNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Contract Start Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.contractStartDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Contract End Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.contractEndDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Contract Details:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Guarantee Period
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          5 years from the date of Schneider Electric EcoStruxure Product Installation
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Limits of Liability Per UPS Event
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $25,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Aggregate Limit During the Term of the Guarantee Period
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          $100,000
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          To submit Claim at
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          Silvia Schneider<br />contact phone: +33(0)615237324<br />contact email: silvia.schneider@se.com
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              }
              {
                (!approved && !rejected && claimModal) &&
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="claim-content"
                    id="claim-header"
                  >
                    <Flag style={claimInCardStyle} />
                    <Typography variant="h6" style={{ color: Colors.darkblue }}>Claim Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1} alignItems="flex-start" direction="row">
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Claim No.:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.claimCaseNumber}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Claim Registration Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.claimDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Claim Incident Date:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word" }}>
                          {modalData.incidentDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          <strong>Claim Details:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Cause
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          {modalData.contractAndPolicySpecificInformation.cause}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Expected Runtime (Sec)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          {Math.round(modalData.contractAndPolicySpecificInformation.expected_runtime * 10) / 10}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography>
                          Real Runtime (Sec)
                        </Typography>
                      </Grid>
                      <Grid item xs={6} >
                        <Typography style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}>
                          {Math.round(modalData.contractAndPolicySpecificInformation.real_runtime * 10) / 10}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              }
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="location-content"
                  id="location-header"
                >
                  <PlaceOutlined style={contractInCardStyle} />
                  <Typography variant="h6" style={{ color: Colors.darkblue }}>Location</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box marginBottom={3}>
                      <Typography variant="h6">
                        The Home Depot
                      </Typography>
                      <Typography>
                        {modalData.location[0]}<br />
                        {modalData.location[1]}<br />
                        {modalData.location[2]}
                      </Typography>
                    </Box>
                    <CardMedia
                      component="img"
                      src={modalData.img}
                      alt="location"
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
              {
                (!approved && !rejected && claimModal) &&
                <Box style={{ marginTop: 15, marginLeft: "5%", marginRight: "3%" }}>
                  <Typography variant="h6" style={{ color: Colors.darkblue }}>Claim Approval</Typography>
                  <Typography>
                    The claim complies with the claim triggering rules and a payout can be initiated.
                  </Typography>

                  <Grid container justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
                    <Grid item xs={6} sm={8}>
                      <Button type="submit" variant="contained" style={buttonStyle} onClick={() => handleApproval(modalData.claimId)}>
                        Approve
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button variant="contained" style={buttonStyle} onClick={() => handleReject(modalData.claimId)}>
                        Reject
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              }
            </CardContent >
          </Box >
        </Card >
      }
    </>
  )
}

function Approval({ handleClose }) {
  return (
    <Card sx={{ maxWidth: 275 }} id="s4">
      <CardHeader
        style={{ backgroundColor: '#00468B', color: "white" }}
        title="Status: Claim Approved"
        titleTypographyProps={{ variant: 'h5' }}
        action={
          <IconButton style={{ color: "white" }} data-dismiss="alert" data-target="#closeablecard">
            <Close onClick={handleClose} />
          </IconButton>
        }
        avatar={
          <Bookmark style={{ color: Colors.active, fontSize: '50' }} />
        }
      />

      <CardContent>
        <Box style={{ marginTop: 15, marginLeft: 53 }}>
          <Typography>
            The claim has been approved. Please initiate claim payout.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

function Rejection({ handleClose }) {
  return (
    <Card sx={{ maxWidth: 275 }} id="s4">
      <CardHeader
        style={{ backgroundColor: '#00468B', color: "white" }}
        title="Status: Claim Rejected"
        titleTypographyProps={{ variant: 'h5' }}
        action={
          <IconButton style={{ color: "white" }} data-dismiss="alert" data-target="#closeablecard">
            <Close onClick={handleClose} />
          </IconButton>
        }
        avatar={
          <Bookmark style={{ color: Colors.claim, fontSize: '50' }} />
        }
      />

      <CardContent>
        <Box style={{ marginTop: 15, marginLeft: 53 }}>
          <Typography>
            The claim has been rejected. No further processing required.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

function Header() {
  const navigate = useNavigate();
  const logout = function () {
    sessionStorage.removeItem('access-token');
    navigate("/");
  }
  // go to login page if already logout
  React.useEffect(() => {
    if (!getAccessToken()) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <>
      <Grid container alignItems="flex-end" direction="row">
        <Grid item xs={8} sm={10} lg={11}>
        </Grid>
        <Grid item xs={4} sm={2} lg={1}>
          <Box marginTop="7%">
            <Button variant="text" startIcon={<PersonOutlined style={{ marginTop: -2 }} />} onClick={logout}>
              Logout
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems="flex-start" direction="row">
        <Grid item xs={12} sm={5}>
          <Box display="grid" gridTemplateColumns="repeat(auto-fill, 5em)" gap={2} style={{ marginLeft: 20, marginTop: 8, height: 40 }}>
            <Box gridColumn="span 1" marginTop="-2px" marginRight={2} position="relative">
              <img src={MunichRE} height="40" alt="MR_Logo" />
            </Box>
            <hr style={dividerLineStyle} />
            <Box gridColumn="span 1" marginTop="-12px" marginLeft="18px" borderColor={Colors.headerLine} position="relative">
              <img src={HSB} height="60" alt="HSB_Logo" />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={7}>
          <Typography component="h1" variant="h4" style={{ color: Colors.darkblue, paddingLeft: 10, marginTop: "16px" }}>
            Insurance Data Platform
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{ width: "100%", height: 10, backgroundColor: Colors.darkblue }}
      />
    </>
  )
}