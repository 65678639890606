import React from 'react';
import { Button, Paper, Typography, Box, Grid, Link, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Colors } from './colors.js';
import { Amplify, Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { AES, enc } from 'crypto-js';

const background = '/img/background_black.svg';
const MunichRE = '/img/MR_Logo_white_RGB.svg';
const HSB = '/img/HSB_vrt_White.svg';

var userMapping = {
  "U2FsdGVkX1/oJvdRdB9Z7XDEtU8qZehDl7W0sLSs81M=": "U2FsdGVkX1+2CICAuLo+zSwBtIjxNsj3mKYRf89eiME=",
  "U2FsdGVkX19ytCfZHQoIDsMRzkO7SzIpS1GEiJI/0c3XkhT42maQq94yYtvsfAwo": "U2FsdGVkX18UdkA6npVv9jp3r9tIAmtsFh7zAnNgmbZZRI+hMvqT6xA4RATN0Llw",
  "U2FsdGVkX18jYrjCmaW4mhKk/8mSTUEsJoRNO0TEabl7nLzZRzyYMBREIrmIK/CN": "U2FsdGVkX1/z0RVzZTnbmVJfhevFnQLdi8ay1FuaPv5X3tbLNnoujOQnMoTT0tY6",
  "U2FsdGVkX18Qq858gPwJAmRJVudLZMb7n6BaKsv1pTAVwc/McG+NaxVjSYKd9ie7": "U2FsdGVkX1+4PW181sCMwbW4Qkk5WFQRjU86ohouywq/JvATcQtVzP7WbWB6g+1b",
  "U2FsdGVkX1+ba3WlfKk7I6tY8t+9vzhvyXHH4NAb7K4y4gIv+pZpxxZv8XGX+a0l": "U2FsdGVkX1+espfbPX42RRmLcQE/KZu6eHKO23h5Q0P64Lh58CzwotHGbJ47C+Cl",
  "U2FsdGVkX1/Dnx66HAxV9ayEbQ3uvvpBL3oEiviGR5YLr+MLRyaHqap2E4W2+1Zt": "U2FsdGVkX1/3wo3ZAVg0TH2tN2eD/PR4qtlT3G4YPka+F1sH1FS+oqYTA9SYvHk7",
  "U2FsdGVkX1+I9vBGKLhNLjPJG+lzFoViMz5g+y0Irmp8hKGx5X/6muySlOBuOkBU": "U2FsdGVkX184DZBCZL0vtHrYqv583Bt5a6m0FTsgRuStdpI3/IJCWiHjihYFHlZ7",
  "U2FsdGVkX19NhL5lyU4ec6BmQ35uIFgabwNJHJeF3ZkAA9kkyWgiF2Dfg8KJ48Ce": "U2FsdGVkX18agL+yb4lmRL+BYdJmNnR1a+yIo3oVU0J9TT3jUHbmOhO6Rx0OWQzx",
  "U2FsdGVkX1+o5YUNpM3nyucFwOvifgfFNgveLH8/WKLescriHudS/D9nRTY84Bq/": "U2FsdGVkX1+eqHtKyzC7Q83L1wvsUbUTKdXKQoMv9+xaBcIMpaJzchkCgt97gHFb",
  "U2FsdGVkX1+uNSTLow5WiviHA4XUPJpLR1B3zT9dF5b+WTNjm64pkEThtccK0LGa": "U2FsdGVkX1/kXKIZ6RnJqmd39npbharK1QbnIjGuAKAD80/izQmQ3v8hh+N+fJ91",
  "U2FsdGVkX19CRMmphmgQKKj2ltGIE1BMqs7LOMJVQNZ3Df0i5E7PDwfP1shF8dHu": "U2FsdGVkX18Hbkehh+Z+3U8vdTx7xhD0kCeyGF9EYTXiBS0uDLt08FNfWs4LSYMN",
  "U2FsdGVkX1/5KLqc7X+Q4RZiD+N6cmK83dc2PhvvbWsk/pdJBRcImXZphmRd5mI7": "U2FsdGVkX1//Be7LoDbBr4nl3eCB+OOVTSOHl8+9SU6tdNHBFwanmRXs9JiHP+CM",
  "U2FsdGVkX18ulUrY9zB9P5IZJrkIGBVfVjaNY/NkNXNUpcD8eGn7jVc5XVsQuvVC": "U2FsdGVkX18zHLifTHXIk6JURqYWaTz/f6reE9LkjGiHnf+YoGnx1x+8yxr0NW6P",
  "U2FsdGVkX1+UFdgf2KKd71we1t7uCLP1u8hn9rkVRwiUtxi+pWT7LQTORJcWgeRA": "U2FsdGVkX1+V+2tT/kKyLBonB8CE5BDMXMaQkl9uDBwkF3OCMAe5yT/BfMotI3ES",
  "U2FsdGVkX1/2APwiley8TwFWGi/3DGg/YLkXPl6wG6EzX+quH7fv9/FxHpXKB1tt": "U2FsdGVkX196WdzDwh60RpplqNk7ym+O2S4AZvFrJNZWlWImSuMQKO2HkesGJH1R",
  "U2FsdGVkX19Fo7V770BF/B6N9Z1Wt/VRnNQVPPWYAz9efbzc7q2z7gbTG2PNxyZC": "U2FsdGVkX19bFhyOrYVSt5dp01A1hcn/SHGG7VL3Kwn3cwnUcvtvGRzxnEjrD4zF",
  "U2FsdGVkX19JVn5ljr5f+mUgcRtly6yjD1jWIFajVCB6L5BBeKrfZV+kpTikUNRM": "U2FsdGVkX19o/BnLaPNQZbbIBGw95qYETiqPIZPoDRsuscTyN/4RoGOtN8fsavRj",
  "U2FsdGVkX1+GupGBCTdi09VX4l0Mi9FMlwveBBoKdnFUlKPtdwNFlWMtadrOgwR8": "U2FsdGVkX1+S/iSonbt4kx3/8xL2BBoY+qPMDo9RYVS8Y3NitEH/J7DZZU9R3dNi",
  "U2FsdGVkX19zot0fZXtJS2CSfxfy7pnJ4u/Ya72yzsrxhBvnXAo4C6P/kE+Zc/+c": "U2FsdGVkX1+Y08G2MCyqNQRtk7OlykN9ONcQEdt0L6LWyWhAS6Xxz/7s+VBAGFWf",
  "U2FsdGVkX1943plWyV8r8tZPGFzOGCaVo8Z1zffK4krnSioLP/3ZtHqEPJpYkqHc": "U2FsdGVkX1+JwD0KBHg/J/gjTfohrE66ohECY/mc9M+IY9aJ5UmzBlYyjAB3mYqd",
  "U2FsdGVkX1+3tMPa2itx15KIBYcc3z8+smxzYGsc8wlb28M/EM9MDSnFGoiSCctx": "U2FsdGVkX19g1WRtZIgw0SZkz5D10+Nzx11Kxih0WYFkkf1UyF0LEujuvSUXT1oC",
  "U2FsdGVkX1+VwASzzKiSIDj7b8syEIoPU/cl4hvXbtfc4e/prLVGQ0udnxGYIShw": "U2FsdGVkX18Uo/BEyH4SOz78EOfJvX2Ft1K4zhXTy8vl6wp7wmRUkkJ4Q45Z5v5c",
  "U2FsdGVkX18q+Dfc3OeASUU2LBlwNvXLFqzpAD37QPBGbxKtjnvXFf/dbnpv75oo": "U2FsdGVkX19hA/jA/evymXjPbWfIZs4dXZZoF45S7STJBjWS1Fwvk1jAevK3tiDs"
}

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "eu-central-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "eu-central-1_rg2APZmCK",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "719tus952vs6iaqnjh3g6rb4kc",

    scopes: ["foo"]
  },
});

var dividerLineStyle = {
  height: 44,
  display: "inline-block",
  border: "1px solid",
  color: "white",
  backgroundColor: "white",
  borderColor: "white",
  marginLeft: 103,
  marginTop: -2
}

const lookupUserName = (username) => {
    var usernameLC = username.toLowerCase();
    var mappedUserName = username
    Object.keys(userMapping).forEach((key) => {
    try{
        const comp = AES.decrypt(key,usernameLC).toString(enc.Utf8)
        if(comp == usernameLC) {
            mappedUserName = AES.decrypt(userMapping[key],usernameLC).toString(enc.Utf8)
        }
    } catch (error) {}
    })
    return mappedUserName;
}

export default function SignIn() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emptyUsername, setEmptyUsername] = React.useState("");
  const [emptyPassword, setEmptyPassword] = React.useState("");
  const navigate = useNavigate();
  const login = function () {
    setEmptyUsername("");
    setEmptyPassword("");
    const realusername = lookupUserName(username);
    Auth.signIn(realusername, password)
      .then((data) => {
        window.authInfo = data
        sessionStorage.setItem('access-token', window.authInfo.signInUserSession.getIdToken().jwtToken);
        console.log(data)
        navigate("/landing")
      }).catch(() => {
        if (username === "") {
          setEmptyUsername("The email is empty, please try again. ")
        }
        if (password === "") {
          setEmptyPassword("The password is empty, please try again. ")
        }
        if (username !== "" && password !== "") {
          setEmptyPassword("The email or password is incorrect, please try again. ");
        }
      })
    //updateFormState(() => ({ ...formState, formType: "signedIn" }));
  };
  return (
    <Paper style={{
      backgroundImage: `url(${background})`, backgroundSize: 'cover', position: 'fixed',
      backgroundRepeat: "no-repeat", width: "100%", height: "100vh", overflowY: "auto"
    }}>
      <Box display="grid" gridTemplateColumns="repeat(auto-fill, 5em)" gap={2} style={{ marginLeft: 20, marginTop: 15, height: 40 }}>
        <Box gridColumn="span 1" marginRight={2} position="relative">
          <img src={MunichRE} height="40" alt="MR_Logo_white" />
        </Box>
        <hr style={dividerLineStyle} />
        <Box gridColumn="span 1" marginTop="-13px" marginLeft={3} borderColor={Colors.headerLine} position="relative">
          <img src={HSB} height="65" alt="HSB_Logo_white" />
        </Box>
      </Box>
      <Box style={{ marginTop: "8%", marginLeft: "10%" }}>
        <Typography component="h1" variant="h4" style={{ color: "white" }}>
          Welcome to IDP - Your Insurance Data Platform
        </Typography>
        <Box mt={2}>
        </Box>
        <Typography variant="subtitle1" style={{ color: "white" }}>
          Leading the connection between IoT and Insurance<br />
          To create a more resilient and sustainable world.
        </Typography>
      </Box>
      <Box style={{ marginTop: "40px", marginLeft: "10%" }}>
        <Typography component="h1" variant="h4" style={{ color: "white" }}>
          Login
        </Typography>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={2} style={{ marginTop: "22px" }}>
            <Grid item xs={10} sm={8}>
              <TextField
                error={(emptyUsername !== "") || (emptyPassword.includes("incorrect"))}
                variant="filled"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={(event) => { setUsername(event.target.value) }}
                inputProps={{ style: { background: "white", borderRadius: "5px" } }}
                helperText={emptyUsername}
              />
            </Grid>
            <Grid item xs={10} sm={8}>
              <TextField
                error={emptyPassword !== ""}
                variant="filled"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={(event) => { setPassword(event.target.value) }}
                autoComplete="current-password"
                inputProps={{ style: { background: "white", borderRadius: "5px" } }}
                helperText={emptyPassword}
              />
            </Grid>
            <Grid item xs={10} sm={8}>
              <FormControlLabel
                control={<Checkbox value="rememberMe" color="primary" style={{ color: "grey" }} />}
                label="Remember me" style={{ color: "grey" }}
              />
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" style={{ marginTop: "5px" }}              >
                <Button type="submit" variant="contained" style={{ mt: 3, mb: 2, backgroundColor: Colors.blue, color: "white" }}
                  onClick={login}>
                  Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={5} sm={4}>
              <Box display="flex" justifyContent="flex-start">
                <Link href="#" variant="body2" style={{ color: "grey" }} disabled>
                  Password Forgotten
                </Link>
              </Box>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Box display="flex" justifyContent="flex-end">
                <Link href="#" variant="body2" style={{ color: "grey" }} disabled>
                  Request Access
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper >
  );
}

